define("discourse/plugins/automation/admin/controllers/admin-plugins-discourse-automation-new", ["exports", "@glimmer/tracking", "@ember/controller", "@ember/object", "@ember/service"], function (_exports, _tracking, _controller, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AutomationNew extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "filterText", [_tracking.tracked], function () {
      return "";
    }))();
    #filterText = (() => (dt7948.i(this, "filterText"), void 0))();
    updateFilterText(event) {
      this.filterText = event.target.value;
    }
    static #_3 = (() => dt7948.n(this.prototype, "updateFilterText", [_object.action]))();
    resetFilterText() {
      this.filterText = "";
    }
    static #_4 = (() => dt7948.n(this.prototype, "resetFilterText", [_object.action]))();
    get scriptableContent() {
      let scripts = this.model.scriptables.content;
      let filter = this.filterText.toLowerCase();
      if (!filter) {
        return scripts;
      }
      return scripts.filter(script => {
        const name = script.name ? script.name.toLowerCase() : "";
        const description = script.description ? script.description.toLowerCase() : "";
        return name.includes(filter) || description.includes(filter);
      });
    }
    selectScriptToEdit(newScript) {
      this.model.automation.save({
        script: newScript.id
      }).then(() => {
        this.router.transitionTo("adminPlugins.discourse-automation.edit", this.model.automation.id);
      });
    }
    static #_5 = (() => dt7948.n(this.prototype, "selectScriptToEdit", [_object.action]))();
  }
  _exports.default = AutomationNew;
});