define("discourse/plugins/automation/admin/templates/admin-plugins-discourse-automation-new", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <section class="discourse-automation-form new">
  
    <div
      class="admin-section-landing__header"
      {{did-insert this.resetFilterText}}
    >
      <h2>{{i18n "discourse_automation.select_script"}}</h2>
  
      <input
        type="text"
        placeholder={{i18n "discourse_automation.filter_placeholder"}}
        {{on "input" this.updateFilterText}}
        class="admin-section-landing__header-filter"
      />
    </div>
  
    {{#unless this.model.scripts.length}}
      <div class="alert alert-info">
        <p>{{i18n "discourse_automation.no_automation_yet"}}</p>
      </div>
    {{/unless}}
  
    <div class="admin-section-landing__wrapper">
      {{#each this.scriptableContent as |script|}}
        <AdminSectionLandingItem
          {{on "click" (fn this.selectScriptToEdit script)}}
          @titleLabelTranslated={{script.name}}
          @descriptionLabelTranslated={{script.description}}
        />
      {{/each}}
    </div>
  
  </section>
  */
  {
    "id": "ELUv/IMT",
    "block": "[[[10,\"section\"],[14,0,\"discourse-automation-form new\"],[12],[1,\"\\n\\n  \"],[11,0],[24,0,\"admin-section-landing__header\"],[4,[38,0],[[30,0,[\"resetFilterText\"]]],null],[12],[1,\"\\n    \"],[10,\"h2\"],[12],[1,[28,[35,1],[\"discourse_automation.select_script\"],null]],[13],[1,\"\\n\\n    \"],[11,\"input\"],[16,\"placeholder\",[28,[37,1],[\"discourse_automation.filter_placeholder\"],null]],[24,0,\"admin-section-landing__header-filter\"],[24,4,\"text\"],[4,[38,2],[\"input\",[30,0,[\"updateFilterText\"]]],null],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[51,[30,0,[\"model\",\"scripts\",\"length\"]]],[[[1,\"    \"],[10,0],[14,0,\"alert alert-info\"],[12],[1,\"\\n      \"],[10,2],[12],[1,[28,[35,1],[\"discourse_automation.no_automation_yet\"],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,0],[14,0,\"admin-section-landing__wrapper\"],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"scriptableContent\"]]],null]],null],null,[[[1,\"      \"],[8,[39,6],[[4,[38,2],[\"click\",[28,[37,7],[[30,0,[\"selectScriptToEdit\"]],[30,1]],null]],null]],[[\"@titleLabelTranslated\",\"@descriptionLabelTranslated\"],[[30,1,[\"name\"]],[30,1,[\"description\"]]]],null],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\\n\"],[13]],[\"script\"],false,[\"did-insert\",\"i18n\",\"on\",\"unless\",\"each\",\"-track-array\",\"admin-section-landing-item\",\"fn\"]]",
    "moduleName": "discourse/plugins/automation/admin/templates/admin-plugins-discourse-automation-new.hbs",
    "isStrictMode": false
  });
});